import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <center>
    <Image
      src="/AWS_AWS_logo_RGB.png"
      padding={tokens.space.medium}
      height="100px" alt=""
    />
    </center>
  );
}
