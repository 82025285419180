
export function regionCountries(region){
  return countryOptions.filter(x => x.region === region );
}

export const regionOptions =  [
    {
      "label": "Select",
      "id": "Select"
    },
    {
      "label": "ANZ",
      "id": "ANZ"
    },
    {
        "label": "ASEAN",
        "id": "ASEAN"
    },
    {
        "label": "CHINA",
        "id": "CHINA"
    },
    {
        "label": "EMEA",
        "id": "EMEA"
    },
    {
      "label": "INDIA",
      "id": "INDIA"
    },
    {
      "label": "JAPAN",
      "id": "JAPAN"
    },
    {
        "label": "KOREA",
        "id": "KOREA"
    },
    {
        "label": "LATAM",
        "id": "LATAM"
    },
    {
        "label": "NA",
        "id": "NA"
    },
    {
      "label": "UKIR",
      "id": "UKIR"
    },
];

export const adminRegionOptions =  [
  {
    "label": "GLOBAL",
    "id": "GLOBAL"
  },
  {
      "label": "ANZ",
      "id": "ANZ"
  },
  {
      "label": "ASEAN",
      "id": "ASEAN"
  },
  {
      "label": "CHINA",
      "id": "CHINA"
  },
  {
    "label": "EMEA",
    "id": "EMEA"
  },
  {
    "label": "INDIA",
    "id": "INDIA"
  },
  {
    "label": "JAPAN",
    "id": "JAPAN"
  },
  {
      "label": "KOREA",
      "id": "KOREA"
  },
  {
      "label": "LATAM",
      "id": "LATAM"
  },
  {
      "label": "NA",
      "id": "NA"
  },
  {
    "label": "UKIR",
    "id": "UKIR"
  },
];


export const countryOptions =
[
    {
      "region": "Select",
      "label": "Select",
      "id": "Select"
    },
    {
      "region": "INDIA",
      "label": "Afghanistan",
      "id": "AF"
    },
    {
      "region": "EMEA",
      "label": "Aland Islands",
      "id": "AX"
    },
    {
      "region": "EMEA",
      "label": "Albania",
      "id": "AL"
    },
    {
      "region": "EMEA",
      "label": "Algeria",
      "id": "DZ"
    },
    {
      "region": "ASEAN",
      "label": "American Samoa",
      "id": "AS"
    },
    {
      "region": "EMEA",
      "label": "Andorra",
      "id": "AD"
    },
    {
      "region": "EMEA",
      "label": "Angola",
      "id": "AO"
    },
    {
      "region": "EMEA",
      "label": "Anguilla",
      "id": "AI"
    },
    {
      "region": "NA",
      "label": "Antigua And Barbuda",
      "id": "AG"
    },
    {
      "region": "LATAM",
      "label": "Argentina",
      "id": "AR"
    },
    {
      "region": "EMEA",
      "label": "Armenia",
      "id": "AM"
    },
    {
      "region": "EMEA",
      "label": "Aruba",
      "id": "AW"
    },
    {
      "region": "ANZ",
      "label": "Australia",
      "id": "AU"
    },
    {
      "region": "EMEA",
      "label": "Austria",
      "id": "AT"
    },
    {
      "region": "EMEA",
      "label": "Azerbaijan",
      "id": "AZ"
    },
    {
      "region": "NA",
      "label": "Bahamas",
      "id": "BS"
    },
    {
      "region": "EMEA",
      "label": "Bahrain",
      "id": "BH"
    },
    {
      "region": "INDIA",
      "label": "Bangladesh",
      "id": "BD"
    },
    {
      "region": "NA",
      "label": "Barbados",
      "id": "BB"
    },
    {
      "region": "EMEA",
      "label": "Belarus",
      "id": "BY"
    },
    {
      "region": "EMEA",
      "label": "Belgium",
      "id": "BE"
    },
    {
      "region": "NA",
      "label": "Belize",
      "id": "BZ"
    },
    {
      "region": "EMEA",
      "label": "Benin",
      "id": "BJ"
    },
    {
      "region": "EMEA",
      "label": "Bermuda",
      "id": "BM"
    },
    {
      "region": "INDIA",
      "label": "Bhutan",
      "id": "BT"
    },
    {
      "region": "LATAM",
      "label": "Bolivia",
      "id": "BO"
    },
    {
      "region": "EMEA",
      "label": "Bosnia and Herzegovina",
      "id": "BA"
    },
    {
      "region": "EMEA",
      "label": "Botswana",
      "id": "BW"
    },
    {
      "region": "LATAM",
      "label": "Brazil",
      "id": "BR"
    },
    {
      "region": "ASEAN",
      "label": "Brunei Darussalam",
      "id": "BN"
    },
    {
      "region": "EMEA",
      "label": "Bulgaria",
      "id": "BG"
    },
    {
      "region": "EMEA",
      "label": "Burkina Faso",
      "id": "BF"
    },
    {
      "region": "ASEAN",
      "label": "Cambodia",
      "id": "KH"
    },
    {
      "region": "EMEA",
      "label": "Cameroon",
      "id": "CM"
    },
    {
      "region": "NA",
      "label": "Canada",
      "id": "CA"
    },
    {
      "region": "EMEA",
      "label": "Cape Verde",
      "id": "CV"
    },
    {
      "region": "EMEA",
      "label": "Cayman Islands",
      "id": "KY"
    },
    {
      "region": "LATAM",
      "label": "Chile",
      "id": "CL"
    },
    {
      "region": "CHINA",
      "label": "China",
      "id": "CN"
    },
    {
      "region": "LATAM",
      "label": "Colombia",
      "id": "CO"
    },
    {
      "region": "EMEA",
      "label": "Comoros",
      "id": "KM"
    },
    {
      "region": "EMEA",
      "label": "Congo",
      "id": "CG"
    },
    {
      "region": "LATAM",
      "label": "Costa Rica",
      "id": "CR"
    },
    {
      "region": "EMEA",
      "label": "Cote d'Ivoire",
      "id": "CI"
    },
    {
      "region": "EMEA",
      "label": "Croatia",
      "id": "HR"
    },
    {
      "region": "LATAM",
      "label": "Cuba",
      "id": "CU"
    },
    {
      "region": "LATAM",
      "label": "Curacao",
      "id": "CW"
    },
    {
      "region": "EMEA",
      "label": "Cyprus",
      "id": "CY"
    },
    {
      "region": "EMEA",
      "label": "Czech Republic",
      "id": "CZ"
    },
    {
      "region": "EMEA",
      "label": "Denmark",
      "id": "DK"
    },
    {
      "region": "NA",
      "label": "Dominica",
      "id": "DM"
    },
    {
      "region": "LATAM",
      "label": "Dominican Republic",
      "id": "DO"
    },
    {
      "region": "LATAM",
      "label": "Ecuador",
      "id": "EC"
    },
    {
      "region": "EMEA",
      "label": "Egypt",
      "id": "EG"
    },
    {
      "region": "LATAM",
      "label": "El Salvador",
      "id": "SV"
    },
    {
      "region": "EMEA",
      "label": "Estonia",
      "id": "EE"
    },
    {
      "region": "EMEA",
      "label": "Ethiopia",
      "id": "ET"
    },
    {
      "region": "ASEAN",
      "label": "Fiji",
      "id": "FJ"
    },
    {
      "region": "EMEA",
      "label": "Finland",
      "id": "FI"
    },
    {
      "region": "EMEA",
      "label": "France",
      "id": "FR"
    },
    {
      "region": "EMEA",
      "label": "Gabon",
      "id": "GA"
    },
    {
      "region": "EMEA",
      "label": "Gambia",
      "id": "GM"
    },
    {
      "region": "EMEA",
      "label": "Georgia",
      "id": "GE"
    },
    {
      "region": "EMEA",
      "label": "Germany",
      "id": "DE"
    },
    {
      "region": "EMEA",
      "label": "Ghana",
      "id": "GH"
    },
    {
      "region": "EMEA",
      "label": "Gibraltar",
      "id": "GI"
    },
    {
      "region": "EMEA",
      "label": "Greece",
      "id": "GR"
    },
    {
      "region": "NA",
      "label": "Grenada",
      "id": "GD"
    },
    {
      "region": "LATAM",
      "label": "Guadeloupe",
      "id": "GP"
    },
    {
      "region": "ASEAN",
      "label": "Guam",
      "id": "GU"
    },
    {
      "region": "LATAM",
      "label": "Guatemala",
      "id": "GT"
    },
    {
      "region": "EMEA",
      "label": "Guinea",
      "id": "GN"
    },
    {
      "region": "NA",
      "label": "Guyana",
      "id": "GY"
    },
    {
      "region": "LATAM",
      "label": "Haiti",
      "id": "HT"
    },
    {
      "region": "LATAM",
      "label": "Honduras",
      "id": "HN"
    },
    {
      "region": "CHINA",
      "label": "Hong Kong",
      "id": "HK"
    },
    {
      "region": "EMEA",
      "label": "Hungary",
      "id": "HU"
    },
    {
      "region": "EMEA",
      "label": "Iceland",
      "id": "IS"
    },
    {
      "region": "INDIA",
      "label": "India",
      "id": "IN"
    },
    {
      "region": "ASEAN",
      "label": "Indonesia",
      "id": "ID"
    },
    {
      "region": "EMEA",
      "label": "Iraq",
      "id": "IQ"
    },
    {
      "region": "UKIR",
      "label": "Ireland",
      "id": "IE"
    },
    {
      "region": "UKIR",
      "label": "Isle of Man",
      "id": "IM"
    },
    {
      "region": "EMEA",
      "label": "Israel",
      "id": "IL"
    },
    {
      "region": "EMEA",
      "label": "Italy",
      "id": "IT"
    },
    {
      "region": "NA",
      "label": "Jamaica",
      "id": "JM"
    },
    {
      "region": "JAPAN",
      "label": "Japan",
      "id": "JP"
    },
    {
      "region": "UKIR",
      "label": "Jersey",
      "id": "JE"
    },
    {
      "region": "EMEA",
      "label": "Jordan",
      "id": "JO"
    },
    {
      "region": "EMEA",
      "label": "Kazakhstan",
      "id": "KZ"
    },
    {
      "region": "EMEA",
      "label": "Kenya",
      "id": "KE"
    },
    {
      "region": "EMEA",
      "label": "Kiribati",
      "id": "KI"
    },
    {
      "region": "KOREA",
      "label": "Korea",
      "id": "KR"
    },
    {
      "region": "EMEA",
      "label": "Kuwait",
      "id": "KW"
    },
    {
      "region": "EMEA",
      "label": "Kyrgyzstan",
      "id": "KG"
    },
    {
      "region": "EMEA",
      "label": "Lao People's Democratic Republic",
      "id": "LA"
    },
    {
      "region": "EMEA",
      "label": "Latvia",
      "id": "LV"
    },
    {
      "region": "EMEA",
      "label": "Lebanon",
      "id": "LB"
    },
    {
      "region": "EMEA",
      "label": "Lesotho",
      "id": "LS"
    },
    {
      "region": "EMEA",
      "label": "Liberia",
      "id": "LR"
    },
    {
      "region": "EMEA",
      "label": "Libyan Arab Jamahiriya",
      "id": "LY"
    },
    {
      "region": "EMEA",
      "label": "Liechtenstein",
      "id": "LI"
    },
    {
      "region": "EMEA",
      "label": "Lithuania",
      "id": "LT"
    },
    {
      "region": "EMEA",
      "label": "Luxembourg",
      "id": "LU"
    },
    {
      "region": "EMEA",
      "label": "Macedonia, The former Yugoslav Republic Of",
      "id": "MK"
    },
    {
      "region": "EMEA",
      "label": "Madagascar",
      "id": "MG"
    },
    {
      "region": "EMEA",
      "label": "Malawi",
      "id": "MW"
    },
    {
      "region": "ASEAN",
      "label": "Malaysia",
      "id": "MY"
    },
    {
      "region": "INDIA",
      "label": "Maldives",
      "id": "MV"
    },
    {
      "region": "EMEA",
      "label": "Mali",
      "id": "ML"
    },
    {
      "region": "EMEA",
      "label": "Malta",
      "id": "MT"
    },
    {
      "region": "LATAM",
      "label": "Martinique",
      "id": "MQ"
    },
    {
      "region": "EMEA",
      "label": "Mauritania",
      "id": "MR"
    },
    {
      "region": "EMEA",
      "label": "Mauritius",
      "id": "MU"
    },
    {
      "region": "LATAM",
      "label": "Mexico",
      "id": "MX"
    },
    {
      "region": "EMEA",
      "label": "Moldova, Republic of",
      "id": "MD"
    },
    {
      "region": "EMEA",
      "label": "Monaco",
      "id": "MC"
    },
    {
      "region": "EMEA",
      "label": "Mongolia",
      "id": "MN"
    },
    {
      "region": "EMEA",
      "label": "Montenegro",
      "id": "ME"
    },
    {
      "region": "EMEA",
      "label": "Morocco",
      "id": "MA"
    },
    {
      "region": "EMEA",
      "label": "Mozambique",
      "id": "MZ"
    },
    {
      "region": "ASEAN",
      "label": "Myanmar",
      "id": "MM"
    },
    {
      "region": "EMEA",
      "label": "Namibia",
      "id": "NA"
    },
    {
      "region": "INDIA",
      "label": "Nepal",
      "id": "NP"
    },
    {
      "region": "EMEA",
      "label": "Netherlands",
      "id": "NL"
    },
    {
      "region": "EMEA",
      "label": "Netherlands Antilles",
      "id": "NL"
    },
    {
      "region": "EMEA",
      "label": "New Caledonia",
      "id": "NC"
    },
    {
      "region": "ANZ",
      "label": "New Zealand",
      "id": "NZ"
    },
    {
      "region": "LATAM",
      "label": "Nicaragua",
      "id": "NI"
    },
    {
      "region": "EMEA",
      "label": "Niger",
      "id": "NE"
    },
    {
      "region": "EMEA",
      "label": "Nigeria",
      "id": "NG"
    },
    {
      "region": "EMEA",
      "label": "Norway",
      "id": "NO"
    },
    {
      "region": "EMEA",
      "label": "Oman",
      "id": "OM"
    },
    {
      "region": "INDIA",
      "label": "Pakistan",
      "id": "PK"
    },
    {
      "region": "LATAM",
      "label": "Panama",
      "id": "PA"
    },
    {
      "region": "ASEAN",
      "label": "Papua New Guinea",
      "id": "PG"
    },
    {
      "region": "LATAM",
      "label": "Paraguay",
      "id": "PY"
    },
    {
      "region": "LATAM",
      "label": "Peru",
      "id": "PE"
    },
    {
      "region": "ASEAN",
      "label": "Philippines",
      "id": "PH"
    },
    {
      "region": "EMEA",
      "label": "Poland",
      "id": "PL"
    },
    {
      "region": "EMEA",
      "label": "Portugal",
      "id": "PT"
    },
    {
      "region": "LATAM",
      "label": "Puerto Rico",
      "id": "PR"
    },
    {
      "region": "EMEA",
      "label": "Qatar",
      "id": "QA"
    },
    {
      "region": "EMEA",
      "label": "Reunion",
      "id": "RE"
    },
    {
      "region": "EMEA",
      "label": "Romania",
      "id": "RO"
    },
    {
      "region": "EMEA",
      "label": "Russia",
      "id": "RU"
    },
    {
      "region": "EMEA",
      "label": "Rwanda",
      "id": "RW"
    },
    {
      "region": "NA",
      "label": "Saint Lucia",
      "id": "LC"
    },
    {
      "region": "NA",
      "label": "Saint Vincent and the Grenadines",
      "id": "VC"
    },
    {
      "region": "EMEA",
      "label": "San Marino",
      "id": "SM"
    },
    {
      "region": "EMEA",
      "label": "Saudi Arabia",
      "id": "SA"
    },
    {
      "region": "EMEA",
      "label": "Senegal",
      "id": "SN"
    },
    {
      "region": "EMEA",
      "label": "Serbia",
      "id": "RS"
    },
    {
      "region": "EMEA",
      "label": "Serbia and Montenegro",
      "id": "RS"
    },
    {
      "region": "EMEA",
      "label": "Seychelles",
      "id": "SC"
    },
    {
      "region": "EMEA",
      "label": "Sierra Leone",
      "id": "SL"
    },
    {
      "region": "ASEAN",
      "label": "Singapore",
      "id": "SG"
    },
    {
      "region": "EMEA",
      "label": "Slovakia",
      "id": "SK"
    },
    {
      "region": "EMEA",
      "label": "Slovenia",
      "id": "SI"
    },
    {
      "region": "EMEA",
      "label": "Somalia",
      "id": "SO"
    },
    {
      "region": "EMEA",
      "label": "South Africa",
      "id": "ZA"
    },
    {
      "region": "EMEA",
      "label": "South Georgia and the South Sandwich Islands",
      "id": "GS"
    },
    {
      "region": "EMEA",
      "label": "South Sudan",
      "id": "SS"
    },
    {
      "region": "EMEA",
      "label": "Spain",
      "id": "ES"
    },
    {
      "region": "INDIA",
      "label": "Sri Lanka",
      "id": "LK"
    },
    {
      "region": "EMEA",
      "label": "Sudan",
      "id": "SD"
    },
    {
      "region": "NA",
      "label": "Suriname",
      "id": "SR"
    },
    {
      "region": "EMEA",
      "label": "Swaziland",
      "id": "SZ"
    },
    {
      "region": "EMEA",
      "label": "Sweden",
      "id": "SE"
    },
    {
      "region": "EMEA",
      "label": "Switzerland",
      "id": "CH"
    },
    {
      "region": "EMEA",
      "label": "Syrian Arab Republic",
      "id": "SY"
    },
    {
      "region": "CHINA",
      "label": "Taiwan",
      "id": "TW"
    },
    {
      "region": "EMEA",
      "label": "Tanzania",
      "id": "TZ"
    },
    {
      "region": "ASEAN",
      "label": "Thailand",
      "id": "TH"
    },
    {
      "region": "ASEAN",
      "label": "Timor-Leste",
      "id": "TL"
    },
    {
      "region": "EMEA",
      "label": "Togo",
      "id": "TG"
    },
    {
      "region": "NA",
      "label": "Trinidad and Tobago",
      "id": "TT"
    },
    {
      "region": "EMEA",
      "label": "Tunisia",
      "id": "TN"
    },
    {
      "region": "EMEA",
      "label": "Turkey",
      "id": "TR"
    },
    {
      "region": "EMEA",
      "label": "Turkmenistan",
      "id": "TM"
    },
    {
      "region": "NA",
      "label": "Turks and Caicos Islands",
      "id": "TC"
    },
    {
      "region": "EMEA",
      "label": "Uganda",
      "id": "UG"
    },
    {
      "region": "EMEA",
      "label": "Ukraine",
      "id": "UA"
    },
    {
      "region": "EMEA",
      "label": "United Arab Emirates",
      "id": "AE"
    },
    {
      "region": "UKIR",
      "label": "United Kingdom",
      "id": "GB"
    },
    {
      "region": "NA",
      "label": "United States",
      "id": "US"
    },
    {
      "region": "NA",
      "label": "United States Minor Outlying Islands",
      "id": "UM"
    },
    {
      "region": "LATAM",
      "label": "Uruguay",
      "id": "UY"
    },
    {
      "region": "EMEA",
      "label": "Uzbekistan",
      "id": "UZ"
    },
    {
      "region": "LATAM",
      "label": "Venezuela",
      "id": "VE"
    },
    {
      "region": "ASEAN",
      "label": "Vietnam",
      "id": "VN"
    },
    {
      "region": "NA",
      "label": "VIRGIN ISLANDS, BRITISH",
      "id": "VG"
    },
    {
      "region": "NA",
      "label": "VIRGIN ISLANDS, U.S.",
      "id": "VI"
    },
    {
      "region": "EMEA",
      "label": "Yemen",
      "id": "YE"
    },
    {
      "region": "EMEA",
      "label": "Zambia",
      "id": "ZM"
    },
    {
      "region": "EMEA",
      "label": "Zimbabwe",
      "id": "ZW"
    }
  ];
