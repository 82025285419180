export const
AmbassadorProgramStatusOptions = [
    {
        "label": "ADMINISTRATOR",
        "id": "ADMINISTRATOR"
    },
    {
        "label": "AMBASSADOR",
        "id": "AMBASSADOR"
    },
    {
        "label": "CANDIDATE",
        "id": "CANDIDATE"
    },
    {
        "label": "IN PROGRESS",
        "id": "IN PROGRESS"
    },
    {
        "label": "REJECTED",
        "id": "REJECTED"
    },
    {
        "label": "RETIRED",
        "id": "RETIRED"
    },
    {
        "label": "SUSPENDED",
        "id": "SUSPENDED"
    },
];
