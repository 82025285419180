import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import { Auth } from 'aws-amplify'

import { getAPNAmbassadorProfile } from "./graphql/queries"

import * as JWT from 'jwt-decode';


import { Link } from 'react-router-dom'

import '@amzn/awsui-components-react/index.css';
import './styles/form.scss';

import {
  AppLayout,
  Button,
  Badge,
  ColumnLayout,
  Icon,
  Spinner, Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from './common/labels';
import './styles/form.scss';

import { AWSCertificationOptions } from './AWSCertifications'

var headshotStyle = {
  float: 'right',
  border: '1px solid #ddd',
  'borderRadius': '4px',
  'padding': '5px',
  width: '100px'
};

const parseSuperpowers = (superpowers) => {

  //console.log(superpowers);
  const sp = superpowers.split(',');

  var spString = "";

  sp.forEach(element => {
    // console.log(element);
    if (element.indexOf('value') != -1) {
      var nv = element.split(':');
      spString = spString + nv[1].replaceAll('"', '') + ", "
    }

  });

  if (sp.length > 0) { spString = spString.slice(0, -2); } else { spString = "-" }

  return spString;

}

export const ProfileDetails = ({ profile, adminView, certifications }) => (
  <ColumnLayout columns={3} variant='text-grid'>
    <div data-awsui-column-layout-root='true'>
      <div>
        <div className='awsui-util-spacing-v-s'>
          <div>
            <div className='awsui-util-label'>Name
            {/*}
              <div>
                {!profile.headshot ?
                  <img src="/Portrait_Placeholder.png" style={headshotStyle} alt=""></img> :
                  <img src={profile.headshot} style={headshotStyle} alt=""></img>
                }
              </div>
              */}
            </div>
            <div>{profile.first_name} {profile.last_name}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Email</div>
            <div>{profile.email}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Country</div>
            <div>
              {!profile.country ? "-" : profile.country}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Region</div>
            <div>
              {!profile.region ? "-" : profile.region}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Member Since</div>
            <div>
              {!profile.member_since ? "-" : profile.member_since}
            </div>
          </div>
{/*
          <div>
            <div className='awsui-util-label'>Profile Picture URL</div>
            <div>
              {!profile.headshot ? "-" : profile.headshot}
            </div>
          </div>
              */}


          <div>
            <div className='awsui-util-label'>Linked In</div>
            <div>
              {!profile.linkedin ? "-" : profile.linkedin}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>X (Twitter)</div>
            <div>
              {!profile.twitter ? "-" : profile.twitter}
            </div>
          </div>
          <div>
            <div className='awsui-util-label'>Shirt Size</div>
            <div>
              {!profile.shirt_size ? "-" : profile.shirt_size}
            </div>
          </div>


        </div>
      </div>
      <div>



        <div className='awsui-util-spacing-v-s'>

          <div>
            <div className='awsui-util-label'>Program Status</div>
            <div>
              {"AMBASSADOR" === profile.program_status ?
                <span className="awsui-util-status-positive"><Icon name="status-positive" /> Ambassador</span>
                :
                <span className="awsui-util-status-negative">{profile.program_status}</span>
              }
            </div>
              <p/>
              <div>
                <div className='awsui-util-label'>Persona</div>
                <div>
                  {!profile.persona ? "-" : profile.persona}
                </div>
              </div>

            <p></p>

            <div className='awsui-util-label'>Community Strength</div>
            <div>{!profile.superpowers ? "-" : parseSuperpowers(profile.superpowers)}</div>
          </div>

          {(profile.program_status == "AMBASSADOR") &&
            <>

              <div>
                <div>
                  <p></p>
                  <div className='awsui-util-label'>Program Level</div>
                  <div>
                    {!profile.program_level ? "-" : 

                    (profile.program_level == "Senior" ? "Ambassador" : profile.program_level)
                    
                    }
                  </div>
                  <div>
                  </div>
                </div>
              </div>


              {(profile.program_level != "Associate") &&
              <>
              <div>
                <div className='awsui-util-label'>Credit Code</div>
                <div>
                  {!profile.current_usage_code ? "-" : profile.current_usage_code}
                </div>
              </div>

              <div>
                <div className='awsui-util-label'>Code Expires</div>
                <div>
                  {!profile.current_usage_code_expiry ? "-" : profile.current_usage_code_expiry}
                </div>
              </div>

              </>
              }

              <p></p>
              <div>
                <div className='awsui-util-label'>Program Compliance</div>
                <div>

                Approved Thought Leadership Contributions: <Badge content={profile.approved_community_contributions} color="green"></Badge><p/>

                Approved Business Contributions: <Badge content={profile.approved_business_contributions} color="green"></Badge>
                 
                </div>
              </div>

            </>
          }
          <div>
            <div className='awsui-util-label'>Job Title</div>
            <div>{!profile.title ? "-" : profile.title}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Partner Name</div>
            <div>{!profile.partner_name ? "-" : profile.partner_name}</div>
          </div>
          <div>
            <div className='awsui-util-label'>Partner Type</div>
            <div>{!profile.partner_type ? "-" : profile.partner_type}</div>
          </div>
          <div>
            <div className='awsui-util-label'>BIO</div>
            <div>
              {!profile.bio ? "-" : profile.bio}
            </div>
          </div>

          {
            (adminView === true) ?
              <span>

                <div>
                  <p />
                  <div className='awsui-util-label'><h4>Administration Status</h4></div>
                </div>
                {/* 
            <div>
              <div className='awsui-util-label'>Previous Credit Code</div>
              <div>
              {!profile.previous_usage_code ? "-" : profile.previous_usage_code}
              </div>
            </div>
           
            <div>
              <div className='awsui-util-label'>Previous Credit Code Expires</div>
              <div>
              {!profile.previous_usage_code_expiry ? "-" : profile.previous_usage_code_expiry}
              </div>
            </div>
          
                <div>
                  <div className='awsui-util-label'>Legal Form</div>
                  <div>
                    {!profile.legal_form ? "-" : profile.legal_form}<p />
                  </div>
                </div>

                  */}
                <div>
                  <div className='awsui-util-label'>SPMS ID</div>
                  <div>
                    {!profile.link_to_nda ? "-" : profile.link_to_nda}<p />
                  </div>
                </div>
                {/*}
            <div>
              <div className='awsui-util-label'>AWS PSA</div>
              <div>
              {!profile.psa ? "-" : profile.psa}<p/>
              </div>
            </div>
           
            <div>
              <div className='awsui-util-label'>Public Sector</div>
              <div>
              {!profile.public_sector ? "-" : profile.public_sector}<p/>
              </div>
          </div>*/}
              </span>
              :
              null
          }
        </div>
      </div>



      <div className='awsui-util-spacing-v-s'>
        <div>
          <div className='awsui-util-label'>AWS Certifications</div>
          <div>
            <ul>
              {
                certifications.map((cert, i) => {
                  return (
                    <li>{AWSCertificationOptions.find(x => x.id === cert.exam_code).label} </li>
                  )
                })
              }
            </ul>
          </div>
        </div>



        {
          (adminView === true) ?
            <>
              <div className='awsui-util-label'>Administration Notes</div>
              {!profile.notes ? "-" : profile.notes}<p />

              <div>
                <div className='awsui-util-label'>Auto Approve Contributions</div>
                <div>
                  {!profile.auto_approve_contributions ? "false" : profile.auto_approve_contributions}
                </div>
              </div>


            </>
            :
            <></>
        }

      </div>
    </div>
  </ColumnLayout>
);

export const ThisProfile = ({ profile, ready, adminView, certifications }) => {

  const loggedInProfile = JSON.parse(localStorage.getItem('apn-ambassador-profile'))

  return (
    <div>
      <span>
        <Link to='/contributions'><Button text='Contributions' /></Link>
        &nbsp;
        {
          (loggedInProfile == null || ((loggedInProfile !== null && loggedInProfile.is_reviewer !== true) || (loggedInProfile !== null && loggedInProfile.cognito_user === profile.cognito_user)))
            ?
            <Link to={"/editProfile/" + profile.cognito_user}><Button text='Edit Profile' /></Link>

            :
            null
        }

        {
          /*
        (loggedInProfile.cognito_user===profile.cognito_user)?

        <Link to={"/editProfile/" + profile.cognito_user}><Button text='Edit Profile'/></Link>  
        :
        null*/
        }


        <p />
      </span>
      <div className='awsui-util-container'>

        <div className='awsui-util-container-header'>
          <h2>Profile Details</h2>
        </div>
        {(ready === true) ?
          <ProfileDetails profile={profile} ready={ready} adminView={adminView} certifications={certifications} /> : <Spinner></Spinner>
        }
      </div>

    </div>

  );
};

class ViewProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: { cognito_user: this.props.match.params.id, first_name: "", last_name: "" },
      flash_message: null,
      ready: false,
      currentUser: { administrator: false },
      certifications: []
    }
  }

  componentWillMount() {

    //console.log("ViewProfile will mount " + this.props.match.params.id);
    // console.log("ViewProfile will mount " + myDecipher(this.props.match.params.id));


    // console.log("ViewProfile will mount props " + JSON.stringify(this.props));
    if (this.props.location.state !== undefined) {
      // console.log("ViewProfile props.state.edit_flash " + this.props.location.state.flash_message);
      this.setState({ flash_message: <Flash dismissible={true} type="success" content={this.props.location.state.flash_message} /> })

    }

    let parent = this;

    Auth.currentAuthenticatedUser()
      .then(user => {
        // console.log(user);
        var token = JWT(user.signInUserSession.accessToken.jwtToken);
        var isAdministrator = false;
        if (token['cognito:groups'] !== undefined && token['cognito:groups'][0] === "APNAmbassadorsAdministrators") { isAdministrator = true; }
        user.attributes.administrator = isAdministrator;
        var profile = this.state.profile;
        profile.email = user.attributes.email;
        parent.setState({ currentUser: user.attributes, profile: profile });


        this.getProfileForUser(this.props.match.params.id);

      }).catch(err => console.log(err)) // end authentication


  } // end will mount


  getProfileForUser(id) {

    // console.log("getProfileForUser " + id);
    // fetch contributions for this user
    API.graphql(graphqlOperation(getAPNAmbassadorProfile, { "cognito_user": id }))
      .then(res => {
        //console.log("Ambassador res ", res )

        var theseCertifications = [];
        // parse certifications
        if (
          res.data.getAPNAmbassadorProfile !== null &&
          res.data.getAPNAmbassadorProfile.certifications !== undefined &&
          res.data.getAPNAmbassadorProfile.certifications !== "" &&
          res.data.getAPNAmbassadorProfile.certifications !== null) {
          theseCertifications = JSON.parse(atob(res.data.getAPNAmbassadorProfile.certifications));
        }

        //console.log(theseCertifications);
        if (res.data.getAPNAmbassadorProfile !== null) {
          this.setState({ profile: res.data.getAPNAmbassadorProfile, ready: true, certifications: theseCertifications })
        } else {
          this.setState({ ready: true })
        }
      }).catch(error => {
        console.log("Couldn't establish ambassador ", error);
        this.setState({ error: "Unable to load profile.", ready: true })
      });

  }


  render() {

    return (
      <div class='awsui'>

        <AppLayout
          contentType='table'
          notifications={this.state.flash_message}

          content={<ThisProfile profile={this.state.profile} ready={this.state.ready} adminView={this.state.currentUser.administrator} certifications={this.state.certifications} />
          }
          navigationHide
          toolsHide
          labels={appLayoutLabels}
        />

      </div>

    );
  }

}

export default ViewProfile;
