export const statusOptions =   [
    {
      label: "ALL",
      id: "ALL",
    },
    {
        label: "PENDING",
        id: "PENDING",
    },
    {
      label: "APPROVED",
      id: "APPROVED",
  },
  {
    label: "REJECTED",
    id: "REJECTED",
  },
  {
    label: "REVIEWING",
    id: "REVIEWING",
  }
];
