import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'

//import { listAPNContributions } from "./graphql/custom-queries";
import { getAPNAmbassadorProfile } from "./graphql/queries";
import { listAPNContributions } from "./graphql/queries";

import '@amzn/awsui-components-react/index.css';
import './styles/form.scss';
import { Auth } from 'aws-amplify'


import {
  AppLayout,
 Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from './common/labels';
import './styles/form.scss';

import PeerContributionsList from "./PeerContributionsList"



import * as JWT from 'jwt-decode';


class Achievements extends Component {
   constructor(props){
     super(props);

     this.state = { 
       contentOrigins: [], toolsIndex: 0, toolsOpen: false, showEditForm: false,
        currentUser: {"email": "nobody","administratior": false, "sub": "none"},
        userProfile: {},
        contributions: [],
        flash_message: null,
        noprofile: true,
        loading: true,
        selectedYear: new Date().getFullYear().toString(),
        selectedRegion: "ALL"
      }
   }


async recursiveAPNContributionsForUser(){

  this.setState({loading:true, contributions: []})
  //var filter = {"filter": {"email": {"eq": this.state.currentUser.email}}};
  var filter = {filter: {}};
  filter.filter.status = {"eq": "APPROVED"}; 
  filter.filter.category = {"eq": "Community"}; 
  filter.limit=100000;

  let fetchedContributions = [];
  if (this.state.selectedYear!=="ALL")
  filter.filter.date_completed = {"between" :[this.state.selectedYear+"-01-01", this.state.selectedYear+"-12-31"]};

  if (this.state.selectedRegion!=="ALL")
  filter.filter.region = {"eq": this.state.selectedRegion}; 

  //console.log(filter)

  const fetchPages = async (nextToken, params) =>{
 
    
     if (nextToken!==""){params.nextToken=nextToken}
     await API.graphql(graphqlOperation(listAPNContributions , params))
     .then(res => { 

       fetchedContributions = fetchedContributions.concat(res.data.listAPNContributions.items);
       //console.log(fetchedContributions.length);

      // console.log(fetchedContributions[0])
       if (
         null!==res.data.listAPNContributions.nextToken )
       {
         fetchPages(res.data.listAPNContributions.nextToken, params);

       }else
       {
         console.log("Token empty.");

         /*
         for (let i=0;i<fetchedContributions.length;i++){
          fetchedContributions[i].full_name = fetchedContributions[i].ambassador.first_name + " " + fetchedContributions[i].ambassador.last_name
        }*/


         this.setState({ contributions: fetchedContributions, loading: false })
       }
     }).catch(error => console.log("Something went wrong: ", error));

   }
   await fetchPages("", filter);

}

  componentWillMount(){
  console.log("will mount");

    const checkCerts = (profile)=>{
      var certifications=[];
      try {
         certifications = JSON.parse(atob(profile.certifications))  ;
      } catch (error) {}
      return certifications.length>0;
    }

    
  let parent = this;

  Auth.currentAuthenticatedUser()
  .then(user => {
   // console.log(user);
    var token = JWT(user.signInUserSession.accessToken.jwtToken);
    var isAdministrator = false;
    if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="APNAmbassadorsAdministrators")
    {isAdministrator=true;}
    user.attributes.administrator=isAdministrator;
    parent.setState({ currentUser: user.attributes});

    this.recursiveAPNContributionsForUser();

   // console.log("getAPNAmbassadorProfile " + user.attributes.sub);
    // fetch contributions for this user
    API.graphql(graphqlOperation(getAPNAmbassadorProfile,{"cognito_user":user.attributes.sub}))
    .then(res => { 
     // console.log("Ambassador profile ", res )
    
        if (res.data.getAPNAmbassadorProfile===null || !checkCerts(res.data.getAPNAmbassadorProfile)) {
          this.setState({ flash_message: <Flash dismissable={true} content="You must update your profile before adding items." /> , noprofile: true})
        }else{
          this.setState({ userProfile: res.data.getAPNAmbassadorProfile, noprofile: false})
        }
    }).catch(error => {
      this.setState({ flash_message: <Flash dismissable={true} content="Somethiong went wrong retrieving your profile. Log out and try again later." /> , noprofile: true})
    });


  }).catch(err => console.log(err)) // end authentication

  } // end will mount

  changeYear(year){
    console.log(year);
    this.setState({ selectedYear: year});
    this.recursiveAPNContributionsForUser();
  }
  changeRegion(region){
    console.log(region);
    this.setState({ selectedRegion: region});
    this.recursiveAPNContributionsForUser();
  }

  render(){

    return (
      <div class='awsui'>

        <AppLayout
        contentType='table'
        notifications={this.state.flash_message}
  
        content={<PeerContributionsList watcher={this} disableAdd={this.state.noprofile} isAdmin={this.state.currentUser.administrator} isReview={this.state.currentUser.is_review} distributions={this.state.contributions} currentUser={this.state.currentUser} updateTools={() => this.setState({ toolsOpen: true })}  loading={this.state.loading}/>}
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        />
  
    
      </div>

    );
  }

}

export default Achievements;
