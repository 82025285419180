import React from 'react';
import { Link } from 'react-router-dom'

import { API, graphqlOperation } from 'aws-amplify'

import { createAPNContribution  , createAmbassadorsApplicationEvent } from "./graphql/mutations";
import { ColumnLayout, Form, FormSection, Textarea, DatePicker, FormField, Input, Select, Button } from '@amzn/awsui-components-react';

import {countryOptions , regionCountries} from './CountriesReference'

import  {getContributionTypes, findContributionById , commonContributionTypes , findCommunityContributionType} from './ContributionsReference' 

class AddContribution extends React.Component {

    constructor(props) {
        super(props);
        console.log(props)
        var selectedRegion = props.userProfile.region;
        var selectedCountryId =  countryOptions.find(x => x.label === props.userProfile.country).id;

        const contributionTypes = getContributionTypes(props.userProfile.partner_type, props.userProfile.program_status);

        this.state = {

            region: selectedRegion, 
            country: selectedCountryId, 
            date_completed: '', 
            description: '', link: '', ace_opportunity_id: null,
            requiresLink: true,
            requiresDescription: true,
            contributionTypes: contributionTypes,
            submitting: false
        };

        this.handleChange = this.handleChange.bind(this);

    }


     validURL(str) {
        var isValidUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
        return isValidUrl.test(str);
     }

    handleCancel(e) {
        e.preventDefault();
        this.props.watcher.cancelClicked();
    }

    handleChange(e, target) {

        console.log(this.props.watcher.state.userProfile.program_status)

        e.preventDefault();
        let change = {}
        console.log("A change");
        console.log(e);
       
        if(e.detail.selectedId!==undefined){
            change[target]=e.detail.selectedId;
            this.setState(change);
            this.forceUpdate();
        }
        if(e.detail.value!==undefined){
            change[target]=e.detail.value;
        }

        if ("region"===target)
        {
            this.setState({country: regionCountries(e.detail.selectedId)[0].id});
        }

        if ("type"===target)
        {
            console.log(findContributionById(e.detail.selectedId)[0].link);
            this.setState({typeValid:true});
            this.setState({requiresLink: findContributionById(e.detail.selectedId)[0].link});
            this.setState({requiresDescription: findContributionById(e.detail.selectedId)[0].description_required!==false});
        }

        this.setState(change);

        if (!this.validURL(this.state.link) && this.state.requiresLink===true){
            this.setState({linkValid:false});
        }else{
            this.setState({linkValid:true});
        }

        console.log(change);

        // clear input errors
        if (this.state.date_completed!==''){
            this.setState({date_completedValid:true});
        }
        var date_completed = Date.parse(this.state.date_completed); 

        var now = new Date();
        now.setDate(now.getDate() + 1);

        var threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

        const program_status = this.props.watcher.state.userProfile.program_status;

        if (date_completed > now) {
            this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be in the future." });
        } else if (date_completed < threeMonthsAgo && (program_status !== "CANDIDATE" && program_status != "IN PROGRESS")) {
            this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be more than 3 months ago." });
        }
        else {
            this.setState({ date_completedErrorText: null });
        }


        console.log(this.state.requiresDescription);
        if (this.state.description==='' && this.state.requiresDescription===true){
            this.setState({descriptionValid:false});
        }else{
            this.setState({descriptionValid:true});

        }


    }


    handleSubmit(e){


        if (this.state.type===undefined){
            this.setState({typeValid:false});
            return;
        }

        //  input errors
        if (this.state.date_completed===''){
            this.setState({date_completedValid:false});
            return;
        }else{
            var date_completed = Date.parse(this.state.date_completed);  
            var now = new Date();
            var threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const program_status = this.props.watcher.state.userProfile.program_status;

            if (date_completed > now) {
                this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be in the future." });
                return;
            } else if (date_completed < threeMonthsAgo && (program_status !== "CANDIDATE" && program_status != "IN PROGRESS")) {
                this.setState({ date_completedValid: false, date_completedErrorText: "Date cannot be more than 3 months ago." });
                return;
            }
        }


        if (!this.validURL(this.state.link) && this.state.requiresLink===true){
            return;
        }

        if (this.state.description==='' && this.state.requiresDescription===true){
            this.setState({descriptionValid:false});
            return;
        }

        var country = countryOptions.find(x => x.id === this.state.country).label;

        const foundType = findCommunityContributionType(this.state.type);

        let category ="Business";
        if ( foundType.length>0){category="Community"}

        const payload = {
            input: {
              cognito_user: this.props.watcher.state.currentUser.sub,
              region:this.state.region,
              email: this.props.watcher.state.currentUser.email,
              output_type: this.state.type,
              description: this.state.description,
              link: this.state.link,
              date_completed: this.state.date_completed,
              country: country,
              ace_opportunity_id: this.state.ace_opportunity_id,
             // status: "PENDING",
              category: category
            } 
          }
          console.log(payload);
         // this.createContribution(payload);
         this.setState({submitting:true});
          this.createAPNContribution(payload);
    }
    

      // next, we create a function to interact with the API
  createAPNContribution = async (payload) => {
 
    await API.graphql(graphqlOperation(createAPNContribution, payload))
    .then(async (res)  =>  {
        const     delay = ms => new Promise(res => setTimeout(res, ms));

      console.log("ambassador output successfully created: ", res )
      await delay(2000);
      
      this.props.watcher.onContributionModified("Contribution created successfully.");
      await delay(2000);
      this.props.watcher.recursiveAPNContributionsForUser();

    })
    .catch(error => console.log("Something went wrong creating output: ", error));
  }
   

  createApplicationEvent = async (eventPayload) => {
 
    await API.graphql(graphqlOperation(createAmbassadorsApplicationEvent, eventPayload))
    .then(res =>  {
      console.log("application event successfully created: ", res )
      // nothing to do
    })
    .catch(error => console.log("Something went wrong creating output: ", error));
  }


    render() {

        var descriptionHTML =<div>Choose from the available contribution types.</div>
        if (this.state.type!==undefined){
            descriptionHTML  = findContributionById(this.state.type)[0].desc;

            descriptionHTML = 
            <>
            {descriptionHTML}
            <p/>
            <div>See the <Link to={"/contributionGuide"} >Contributions Guide</Link> for the requirements.</div>

            </>
        }

       // console.log(this.state.type);
        //console.log(findContributionById(this.state.type)[0].desc);

        return (




                    <Form
                header="Add Contribution"
                actions={<div>
                    <Button text="Cancel" variant="link" onClick={(e) => this.handleCancel(e)}></Button>
                    <Button text="Submit" variant="primary" disabled={this.state.submitting} onClick={(e) => this.handleSubmit(e)}></Button>
                </div>}


                    >
                    <FormSection header="Enter details here">
                  

                    <ColumnLayout columns={2}>
                    <div data-awsui-column-layout-root="true">
                    <div>



                  <p />
                  <FormField label="Contribution Type" description="What type of contribution?" ariaRequired={true}>
                      <Select name="type" invalid={this.state.typeValid===false} options={this.state.contributionTypes} selectedId={this.state.type} placeholder="Select Type" onChange={(e) => {this.handleChange(e, "type")}} ></Select>
                  </FormField>
                  <p />
                  <FormField label="Date Completed" description="When was the item created or published?"  errorText={this.state.date_completedErrorText}>

                      <DatePicker
                          name="date_completed"
                          placeholder="YYYY/MM/DD"
                          todayLabel="Today"
                          nextMonthLabel="Next month"
                          previousMonthLabel="Previous month"
                          ariaRequired={true}
                          invalid={this.state.date_completedValid===false}
                         
                          onChange={(e) => {this.handleChange(e, "date_completed")}}
                      ></DatePicker>
                  </FormField>

                {(this.state.type=="APN Customer Engagements - Opportunity") &&
                    <>
                  <p />
                  <FormField label='ACE Opportunity ID' description="Provide a ACE opportunity Id" >
                      <Input ariaRequired={true} name="ace_opportunity_id"  invalid={this.state.ace_opportunity_id===false} onChange={(e) => {this.handleChange(e, "ace_opportunity_id")}} />
                  </FormField>

                  <p />
                  </>
                }

                {(this.state.requiresLink==true) &&
                    <>

                  <p />
                  <FormField label='Link' description="Provide a valid link to the contribution (include http://)" >
                      <Input ariaRequired={true} name="link"  invalid={this.state.linkValid===false} onChange={(e) => {this.handleChange(e, "link")}} />
                  </FormField>
                  <p />
                  </>
                }


                  <p />
                  <FormField label='Description' description="Describe the contribution." >
                      <Textarea ariaRequired={true} name="description"  invalid={this.state.descriptionValid===false}  onChange={(e) => {this.handleChange(e, "description")}} />
                  </FormField>
                  <p />

                  </div>

                    <div>
                    <p/>
                    <h5><b>Description</b></h5>
                    {descriptionHTML}
                    



                    </div>
</div>
</ColumnLayout>


              </FormSection>

          </Form>


                   

   


        )
    }

}

export default AddContribution;
