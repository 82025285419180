import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'

import { listAPNContributions } from "./graphql/queries";
import { getAPNAmbassadorProfile } from "./graphql/queries";

import '@amzn/awsui-components-react/index.css';
import './styles/form.scss';
import { Auth } from 'aws-amplify'


import {
  AppLayout,
 Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from './common/labels';
import './styles/form.scss';

import ContributionsList from "./ContributionsList"
import AddContribution from "./AddContribution"
import EditContribution from "./EditContribution"


import * as JWT from 'jwt-decode';


class Achievements extends Component {
   constructor(props){
     super(props);

     this.state = { 
       contentOrigins: [], toolsIndex: 0, toolsOpen: false, showEditForm: false,
        currentUser: {"email": "nobody","administratior": false, "sub": "none"},
        userProfile: {},
        contributions: [],
        flash_message: null,
        noprofile: true,
        loading: true,
        selectedYear: new Date().getFullYear().toString()
      }
   }


async recursiveAPNContributionsForUser(){

  this.setState({loading:true, contributions: []})
  var filter = {"filter": {"email": {"eq": this.state.currentUser.email}}};
  filter.limit=100000;

  let fetchedContributions = [];
  if (this.state.selectedYear!=="ALL")
  filter.filter.date_completed = {"between" :[this.state.selectedYear+"-01-01", this.state.selectedYear+"-12-31"]};

  console.log(filter)

  const fetchPages = async (nextToken, params) =>{
 
    
     if (nextToken!==""){params.nextToken=nextToken}
     await API.graphql(graphqlOperation(listAPNContributions , params))
     .then(res => { 

       fetchedContributions = fetchedContributions.concat(res.data.listAPNContributions.items);
       console.log(fetchedContributions.length);

       if (
         null!==res.data.listAPNContributions.nextToken )
       {
         fetchPages(res.data.listAPNContributions.nextToken, params);

       }else
       {
         console.log("Token empty.");
         this.setState({ contributions: fetchedContributions, loading: false })
       }
     }).catch(error => console.log("Something went wrong: ", error));

   }
   await fetchPages("", filter);

}

  componentWillMount(){
  console.log("will mount");

    const checkCerts = (profile)=>{
      var certifications=[];
      try {
         certifications = JSON.parse(atob(profile.certifications))  ;
      } catch (error) {}
      return certifications.length>0;
    }

    
  let parent = this;

  Auth.currentAuthenticatedUser()
  .then(user => {
   // console.log(user);
    var token = JWT(user.signInUserSession.accessToken.jwtToken);
    var isAdministrator = false;
    if (token['cognito:groups']!==undefined && token['cognito:groups'][0]==="APNAmbassadorsAdministrators")
    {isAdministrator=true;}
    user.attributes.administrator=isAdministrator;
    parent.setState({ currentUser: user.attributes});

    this.recursiveAPNContributionsForUser();

   // console.log("getAPNAmbassadorProfile " + user.attributes.sub);
    // fetch contributions for this user
    API.graphql(graphqlOperation(getAPNAmbassadorProfile,{"cognito_user":user.attributes.sub}))
    .then(res => { 
     // console.log("Ambassador profile ", res )
    
        if (res.data.getAPNAmbassadorProfile===null || !checkCerts(res.data.getAPNAmbassadorProfile)) {
          this.setState({ flash_message: <Flash dismissable={true} content="You must update your profile before adding items." /> , noprofile: true})
        }else{
          this.setState({ userProfile: res.data.getAPNAmbassadorProfile, noprofile: false})
        }
    }).catch(error => {
      this.setState({ flash_message: <Flash dismissable={true} content="Somethiong went wrong retrieving your profile. Log out and try again later." /> , noprofile: true})
    });


  }).catch(err => console.log(err)) // end authentication

  } // end will mount

  createClicked(){
    this.setState({showEditForm: true})
  }

  editClicked(selections){
    console.log(selections[0]);
    this.setState({showEditForm: true, edit: true, selected: selections[0]});
  }

  cancelClicked(){
    this.setState({showEditForm: false, edit: false})
  }

  async onContributionModified(message){
    this.setState({ showEditForm: false, flash_message: <Flash dismissible={true} type="success" content={message} />})
  }


  setFlashMessage(flash){
    this.setState({ flash_message: flash})
  }

  changeYear(year){
    console.log(year);
    this.setState({ selectedYear: year});
    this.recursiveAPNContributionsForUser();
  }


  changeStatusFilter(status){
    console.log(status);
    this.setState({ selectedStatus: status});
    //this.recursiveLoadRegionalAchievements();
  }

  render(){

    return (
      <div class='awsui'>

      {

        (this.state.showEditForm === true) ? 

        <span>
          {
            (this.state.edit === true) ? 
            <AppLayout
            contentType='form'  
            content={<EditContribution watcher={this} isAdmin={this.state.currentUser.administrator} success=
            {this.onContributionModified} selected={this.state.selected} userProfile={this.state.userProfile} currentUser={this.state.currentUser}/>}
            navigationHide
            toolsHide
            labels={appLayoutLabels}
          /> 
            
            
            : 
          
            <AppLayout
              contentType='form'  
              content={<AddContribution watcher={this} isAdmin={this.state.currentUser.administrator} success=
              {this.onContributionModified} userProfile={this.state.userProfile} currentUser={this.state.currentUser}/>}
              navigationHide
              toolsHide
              labels={appLayoutLabels}
            />


          }
        </span>
        
      
       :
        <AppLayout
        contentType='table'
        notifications={this.state.flash_message}
  
        content={<ContributionsList watcher={this} disableAdd={this.state.noprofile} isAdmin={this.state.currentUser.administrator} isReview={this.state.currentUser.is_review} distributions={this.state.contributions} currentUser={this.state.currentUser} updateTools={() => this.setState({ toolsOpen: true })}  loading={this.state.loading}/>}
        navigationHide
        toolsHide
        labels={appLayoutLabels}
        />
    
      
      /* end  if show  create form */
      }


      

      </div>

    );
  }

}

export default Achievements;
