import React from 'react';
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'

import { deleteAPNContribution } from "./graphql/mutations";
import { getFilterCounterText } from './common/tableCounterStrings';
import * as localBStorage from './common/localStorage';

import {yearOptions} from './YearOptions'

import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
  } from './contributions/table-config';
  import {
    paginationLabels,
    distributionSelectionLabels
  } from './common/labels';
  import {
    Button,
    Table,
    TablePagination,
    TableSelection,
    TableFiltering,

    TableSorting,
    Flash,Spinner,Select
  } from '@amzn/awsui-components-react';
  import './styles/table.scss';
  import { createPropertyStorage } from './common/createPropertyStorage';
  import * as lStorage from './common/localStorage';
  import { mapWithColumnDefinitionIds, addToColumnDefinitions } from './common/columnDefinitionsHelper';

  var floatRightStyle = {
    float: 'right'
  };

  const widthsStorage = createPropertyStorage('React-Table-Widths', lStorage);
  const saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
  const loadWidths = () =>
    widthsStorage.load().then(columns => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
  
    export const TableHeader = ({ selectedDistributions, counter, updateTools , watcher , table, disableAdd , loading}) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
      
        function handleClick(e) {
            e.preventDefault();
            watcher.createClicked();
          }

          function handleEdit(e) {
            e.preventDefault();
            watcher.editClicked(selectedDistributions);
          }

          function handleDelete(e) {
            e.preventDefault();
           
            var payload={
              input: {
                "id": selectedDistributions[0].id
              }
            }

            console.log(JSON.stringify(payload));
            delContribution(payload);
           
          }


          const delContribution = async (payload) => {
            await API.graphql(graphqlOperation(deleteAPNContribution, payload))
            .then(res =>  {
    
                console.log(JSON.stringify(res));

                if (res.data.deleteAPNContribution!=null){
                    console.log("contribution successfully deleted: ", res );
                   

                    var newFlash = <Flash dismissible={true} type="success" visible={true} content={"Successfully deleted item with id:" + res.data.deleteAPNContribution.id}/>;
                    watcher.setFlashMessage(newFlash);
                    watcher.recursiveAPNContributionsForUser();


                }else{

                    watcher.setFlashMessage(<Flash dismissible={true} type="error"  visible={true} content={"Something went wrong. Try again later."} />);
                }
              
            })
            .catch(error => console.log("Something went wrong updating contribution: ", error));
          }

        return (
          <div className='awsui-util-action-stripe'>

            <div className='awsui-util-action-stripe-title'>


              <h1 className='awsui-util-font-size-3 awsui-util-d-ib'>
                <strong>My Contributions </strong>
                {(loading===true)
                  ?
                  <Spinner></Spinner>
                  : <></>
                  }
              </h1>
            </div>
            <div className='awsui-util-action-stripe-group'>
              <Button text='Delete' disabled={!isOnlyOneSelected || selectedDistributions[0].status==="APPROVED"} onClick={handleDelete}/>

              <Button text='Edit' disabled={!isOnlyOneSelected || selectedDistributions[0].status==="APPROVED"} onClick={handleEdit}/>
            
              <Button variant='primary' disabled={disableAdd}
              text='Add Contribution' onClick={handleClick}/>
            </div>

          </div>
        );
      };
  
class ContributionsList extends React.Component {

    constructor(props) {
      super(props);
      console.log(props)
      var showPeerContributions = false;
      const loggedInProfile = JSON.parse(localStorage.getItem('apn-ambassador-profile'))
      console.log(loggedInProfile)
      try {
        console.log(loggedInProfile.program_status)
        if (
          loggedInProfile.program_status==="AMBASSADOR"
          ||
          loggedInProfile.program_status==="ADMINISTRATOR"
          )
          showPeerContributions = true;
      } catch (error) {
        console.log(error)
      }


      this.state = {
        columnDefinitions: COLUMN_DEFINITIONS,
        selectedDistributions: [],
        filteringText: '',
        pageSize: 30,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        wrapLines: false,
        isAdmin: false,
        selectedYear: new Date().getFullYear().toString(),
        showPeerContributions: showPeerContributions
      };
      this.onPaginationChange = this.onPaginationChange.bind(this);
    }


    componentDidMount() {

     //console.log(this.props.watcher.state.userProfile);
      loadWidths().then(columnDefinitions => {
        this.setState({
          columnDefinitions
        });
      });
    }
  
    onFilteringChange({ detail: { filteringText } }) {
      this.setState({
        filteringText
      });
    }
  
    onPaginationChange({ detail: { pageSize } }) {
      this.setState({
        pageSize
      });
    }
  
    onContentSelectionChange({ detail}) {
      const contentSelection = detail.contentSelection;
      const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
      this.setState({
        contentSelectorOptions: [{
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1
          }))
        }]
      });
    }
  
    onWrapLinesChange({ detail }) {
      this.setState({
        wrapLines: detail.value
      });
    }
  
    headerCounter(selectedDistributions, distributions) {
      return selectedDistributions.length
        ? `(${selectedDistributions.length}/${distributions.length})`
        : `(${distributions.length})`;
    }
  
    clearFilter() {
      this.setState({
        filteringText: ''
      });
    }


    handleSelectYear(e){
      e.preventDefault();
      console.log(e);
      this.setState({selectedYear: e.detail.selectedId});
      console.log(this.props.watcher);
      this.props.watcher.changeYear(e.detail.selectedId);
    }

    render() {


      return (
        <div>
         <span>
          { (this.props.isAdmin === true) ? 
           <span style={floatRightStyle}>
          <b>Administration:</b> &nbsp;


            <Link to='/profiles'><Button variant="primary" text='Profiles'/></Link>&nbsp;

           <Link to='/achievementsAdmin'><Button variant="primary" text='Contributions'/></Link>
         </span> : null 
          }
          &nbsp;

          <div style={{float: 'left', marginTop: '5px'}}>
            <b>Year: </b>&nbsp;&nbsp;
          </div>
          <div style={{float: 'left'}}>

          <Select name="year" options={yearOptions} selectedId={this.state.selectedYear}  onChange={(e) => {this.handleSelectYear(e)}} ></Select>
          &nbsp;&nbsp;
          </div>

          <Link to={"/viewProfile/" + this.props.currentUser.sub}><Button text='My Profile'/></Link>&nbsp;&nbsp;
          
          { this.state.showPeerContributions &&
          <><Link to={"/peerContributions"}><Button text='Peer Contributions'/></Link>&nbsp;&nbsp;</>
          }

          <Link to={"/contributionGuide"} ><Button text='Contributions Guide'/></Link>&nbsp;&nbsp;
          {/*<CriteriaGuideButton/>
          <Link to={"/criteriaGuide"}><Button text='Criteria Guide'/></Link>
          */}

          <p/>
          </span>

        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.props.distributions}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          header={(
            <TableHeader
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(this.state.selectedDistributions, this.props.distributions)}
              watcher={this.props.watcher}
              table={this}
              disableAdd={this.props.disableAdd}
              loading={this.props.loading}
            />
          )}
          
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >

<TableFiltering
          filteringLabel='Search Contributions'
          filteringPlaceholder='Search Contributions'
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          filteringCountTextFunction={getFilterCounterText}
        />


          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          <TableSorting sortableColumns={SORTABLE_COLUMNS}
                sortingColumn={'created'}
                sortingDescending={true}
          />

            
            <TableSelection
            selectionType={"single"}
            selectedItems={this.state.selectedDistributions}
            labels={distributionSelectionLabels}
            onSelectionChange={evt => this.setState({selectedDistributions: evt.detail.selectedItems})}
          />
            
    
        
        </Table>
        </div>
      )
    }
  }

  export default ContributionsList;