import React from 'react';
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import {
    COLUMN_DEFINITIONS,
    CONTENT_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
  } from './profiles/profiles-table-config';
  import {
    paginationLabels,
    distributionSelectionLabels
  } from './common/labels';
  import {
    Button,
    Table,
    TableFiltering,
    TablePagination,
    TableSelection,
    TableSorting,Select
  } from '@amzn/awsui-components-react';
  import { getFilterCounterText } from './common/tableCounterStrings';
  import './styles/table.scss';
  import { createPropertyStorage } from './common/createPropertyStorage';
  import * as localStorage from './common/localStorage';
  import { mapWithColumnDefinitionIds, addToColumnDefinitions } from './common/columnDefinitionsHelper';
  import { API, graphqlOperation } from 'aws-amplify'

  import { getAPNAmbassadorProfile} from "./graphql/queries";
  import { listAPNAmbassadorProfiles} from "./graphql/custom-queries";

  import {AmbassadorProgramStatusOptions} from './AmbassadorProgramStatusOptions'


var statusOptions = [...AmbassadorProgramStatusOptions] ;

statusOptions.unshift( 
      {
      label: "ALL",
      id: "ALL",
      });

  var floatRightStyle = {
    float: 'right'
  };
  const widthsStorage = createPropertyStorage('React-Table-Widths', localStorage);
  const saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(COLUMN_DEFINITIONS, 'width', e.detail.widths));
  const loadWidths = () =>
    widthsStorage.load().then(columns => addToColumnDefinitions(COLUMN_DEFINITIONS, 'width', columns));
  
    export const TableHeader = ({ selectedDistributions, counter, updateTools , watcher , table , region }) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
  

        return (
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h1 className='awsui-util-font-size-3 awsui-util-d-ib'>
                <strong>Ambassador Profiles (Region: {region})</strong>
              </h1>
            </div>
            <div className='awsui-util-action-stripe-group'>
              {
                (isOnlyOneSelected) ? 
                <div><Link to={"/viewProfile/" + selectedDistributions[0].cognito_user}>&nbsp;<Button disabled={!isOnlyOneSelected} text='View Details'/></Link>&nbsp;</div>
                : 
                <div><a href="#vd"><Button disabled={!isOnlyOneSelected} text='View Details'/></a>&nbsp;</div>
              }
              {
                /*
              <a><Button text='Delete' disabled={selectedDistributions.length === 0} onClick={handleDelete}/></a>&nbsp;
              <a><Button variant='primary' text='Add Profile' onClick={handleClick}/></a>

                */

              }
            </div>

          </div>
        );
      };
  
class ProfilesList extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        columnDefinitions: COLUMN_DEFINITIONS,
        selectedDistributions: [],
        profiles: [],
        filteringText: '',
        pageSize: 30,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        wrapLines: false,
        selectedStatus: 'AMBASSADOR',
        regionFilter: 'GLOBAL'
      };
      this.onPaginationChange = this.onPaginationChange.bind(this);
    }
  


    async findUserAdminRegion() {

      await Auth.currentAuthenticatedUser()
      .then(user => {
       // console.log(user);
        
        API.graphql(graphqlOperation(getAPNAmbassadorProfile,{"cognito_user":user.username}))
        .then(res => { 
          //console.log("Ambassador res ", res )
    
  
           if (
            res.data.getAPNAmbassadorProfile.admin_region!==null && 
             res.data.getAPNAmbassadorProfile.admin_region!=="" && 
             res.data.getAPNAmbassadorProfile.admin_region!=="GLOBAL"
            ){
             this.setState({regionFilter: res.data.getAPNAmbassadorProfile.admin_region})
            }

            this.loadProfiles();
    
        }).catch(err => console.log(err)) // end authentication
  
      }).catch(err => console.log(err)) // end authentication

    } // end find user region

     async loadProfiles(){

      const loadProfilePages = async(nextToken, params) =>{
      console.log(params);

        if (nextToken!==""){params.nextToken=nextToken}

  
        await API.graphql(graphqlOperation(listAPNAmbassadorProfiles,params))
        .then(res => { 
          console.log("Something went right: ", res )

          fetchedProfiles = fetchedProfiles.concat(res.data.listAPNAmbassadorProfiles.items);
          console.log(fetchedProfiles.length)
         if (null!==res.data.listAPNAmbassadorProfiles.nextToken)
         {
          loadProfilePages(res.data.listAPNAmbassadorProfiles.nextToken, params);
         }else{
          console.log("Token empty.");
          this.setState({ profiles: fetchedProfiles})
         }

        }).catch(error => console.log("Something went wrong: ", error));
  
  
      }

      let fetchedProfiles = [];

      var thisFilter={};
      if(this.state.regionFilter!=="GLOBAL"){
              if (this.state.selectedStatus!=="ALL"){
                // both region and program_status
                thisFilter = {"filter": {
                                        "and": [
                                                {"program_status": {"eq": this.state.selectedStatus}},
                                                {"region": {"eq": this.state.regionFilter}}
                                              ] 
                                        }
                              };
              }else{
              // only filter region
              thisFilter = {"filter": {"region": {"eq": this.state.regionFilter}}};
              }
        }else{
          if (this.state.selectedStatus!=="ALL"){
            // only filter program_status
            thisFilter = {"filter": {"program_status": {"eq": this.state.selectedStatus}}};
            //console.log(thisFilter);
          }
        } 
      thisFilter.limit=100;
      await loadProfilePages("", thisFilter);
    }

    async loadProfilesOld(){
      var thisFilter={};
      if(this.state.regionFilter!=="GLOBAL"){
              if (this.state.selectedStatus!=="ALL"){
                // both region and program_status
                thisFilter = {"filter": {
                                        "and": [
                                                {"program_status": {"eq": this.state.selectedStatus}},
                                                {"region": {"eq": this.state.regionFilter}}
                                              ] 
                                        }
                              };
              }else{
              // only filter region
              thisFilter = {"filter": {"region": {"eq": this.state.regionFilter}}};
              }
        }else{
          if (this.state.selectedStatus!=="ALL"){
            // only filter program_status
            thisFilter = {"filter": {"program_status": {"eq": this.state.selectedStatus}}};
            //console.log(thisFilter);
          }
        } 
      thisFilter.limit=5000;

      //console.log(thisFilter);

      await API.graphql(graphqlOperation(listAPNAmbassadorProfiles,thisFilter))
      .then(res => { 
        //console.log("Something went right: ", res )
        this.setState({ profiles: res.data.listAPNAmbassadorProfiles.items})
      }).catch(error => console.log("Something went wrong: ", error));


    }


    handleSelectStatus(e){
      //console.log(e.detail.selectedId);
      this.setState({selectedStatus: e.detail.selectedId});
      this.loadProfiles();
    }

    componentWillMount(){
        
      this.findUserAdminRegion();
     
      } // end will mount


    componentDidMount() {
      loadWidths().then(columnDefinitions => {
        this.setState({
          columnDefinitions
        });
      });   
      
      //console.log(this.props.filterText);
      if (this.props.filterText!==undefined && this.props.filterText!==""){
        //console.log(this.props.filterText);
        this.setState({filteringText: this.props.filterText});
      }
    }
  
    onFilteringChange({ detail: { filteringText } }) {
      this.setState({
        filteringText
      });
    }
  
    onPaginationChange({ detail: { pageSize } }) {
      this.setState({
        pageSize
      });
    }
  
    onContentSelectionChange({ detail}) {
      const contentSelection = detail.contentSelection;
      const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
      this.setState({
        contentSelectorOptions: [{
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1,
          }))
        }]
      });

     
    }
  
    onWrapLinesChange({ detail }) {
      this.setState({
        wrapLines: detail.value
      });
    }
  
    headerCounter(selectedDistributions, distributions) {
      return selectedDistributions.length
        ? `(${selectedDistributions.length}/${distributions.length})`
        : `(${distributions.length})`;
    }
  
    clearFilter() {
      this.setState({
        filteringText: ''
      });
    }
  
    deleteSelections(){

        alert(this.state.selectedDistributions);
    }


    render() {
      return (
        <div>

        <span>
        &nbsp;&nbsp;
          <Link to='/contributions'><Button text='Contributions'/></Link>&nbsp;
          <Link to={"/viewProfile/" + this.props.watcher.state.currentUser.sub}>&nbsp;<Button text='View My Profile'/></Link>

          &nbsp;
          <div style={{float: 'left', marginTop: '5px'}}>
            <b>Status: </b>&nbsp;&nbsp;
          </div>
          <div style={{float: 'left', width: '150px'}}>
          <Select class="select-filter" name="year" options={statusOptions} selectedId={this.state.selectedStatus}  onChange={(e) => {this.handleSelectStatus(e)}} ></Select>
        </div>

          <span style={floatRightStyle}>
          <b>Administration:</b> &nbsp;
           <Link to='/profiles'><Button disabled={true} variant="primary" text='Profiles'/></Link>&nbsp;
           <Link to='/achievementsAdmin'><Button variant="primary" text='Contributions'/></Link>
         </span>
          <p/>
        </span>
       
        <Table
          columnDefinitions={this.state.columnDefinitions}
          items={this.state.profiles}
          wrapLines={true}
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          header={(
            <TableHeader
              selectedDistributions={this.state.selectedDistributions}
              counter={this.headerCounter(this.state.selectedDistributions, this.state.profiles)}
              watcher={this.props.watcher}
              table={this}
              region={this.state.regionFilter}
            />
          )}
          
          onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        >

      <TableFiltering
          filteringLabel='Search Profiles'
          filteringPlaceholder='Search Profiles'
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          filteringCountTextFunction={getFilterCounterText}
        />

          <TablePagination
            onPaginationChange={this.onPaginationChange}
            labels={paginationLabels}
            pageSize={this.state.pageSize}
          />
          <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
          <TableSelection
            selectionType={"single"}
            selectedItems={this.state.selectedDistributions}
            labels={distributionSelectionLabels}
            onSelectionChange={evt => this.setState({selectedDistributions: evt.detail.selectedItems})}
          />
        </Table>
        </div>
      )
    }
  }

  export default ProfilesList;