/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAPNAmbassadorProfile = /* GraphQL */ `
  mutation CreateAPNAmbassadorProfile(
    $input: CreateAPNAmbassadorProfileInput!
    $condition: ModelAPNAmbassadorProfileConditionInput
  ) {
    createAPNAmbassadorProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      partner_name
      country
      region
      bio
      twitter
      linkedin
      headshot
      title
      shirt_size
      member_since
      certifications
      program_status
      total_score
      total_approved_contributions
      approved_community_contributions
      approved_business_contributions
      global_rank
      regional_rank
      superpowers
      partner_type
      public_sector
      program_status_modified_by
      program_status_modified_date
      psa
      legal_form
      link_to_nda
      previous_usage_code
      previous_usage_code_expiry
      current_usage_code
      current_usage_code_expiry
      is_disabled
      is_admin
      is_reviewer
      admin_region
      notes
      executive_sponsor
      executive_sponsor_email
      retirement_reason
      retirement_date
      suspended_date
      program_level
      auto_approve_contributions
      persona
      createdAt
      updatedAt
    }
  }
`;
export const updateAPNAmbassadorProfile = /* GraphQL */ `
  mutation UpdateAPNAmbassadorProfile(
    $input: UpdateAPNAmbassadorProfileInput!
    $condition: ModelAPNAmbassadorProfileConditionInput
  ) {
    updateAPNAmbassadorProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      partner_name
      country
      region
      bio
      twitter
      linkedin
      headshot
      title
      shirt_size
      member_since
      certifications
      program_status
      total_score
      total_approved_contributions
      approved_community_contributions
      approved_business_contributions
      global_rank
      regional_rank
      superpowers
      partner_type
      public_sector
      program_status_modified_by
      program_status_modified_date
      psa
      legal_form
      link_to_nda
      previous_usage_code
      previous_usage_code_expiry
      current_usage_code
      current_usage_code_expiry
      is_disabled
      is_admin
      is_reviewer
      admin_region
      notes
      executive_sponsor
      executive_sponsor_email
      retirement_reason
      retirement_date
      suspended_date
      program_level
      auto_approve_contributions
      persona
      createdAt
      updatedAt
    }
  }
`;
export const deleteAPNAmbassadorProfile = /* GraphQL */ `
  mutation DeleteAPNAmbassadorProfile(
    $input: DeleteAPNAmbassadorProfileInput!
    $condition: ModelAPNAmbassadorProfileConditionInput
  ) {
    deleteAPNAmbassadorProfile(input: $input, condition: $condition) {
      cognito_user
      email
      first_name
      last_name
      partner_name
      country
      region
      bio
      twitter
      linkedin
      headshot
      title
      shirt_size
      member_since
      certifications
      program_status
      total_score
      total_approved_contributions
      approved_community_contributions
      approved_business_contributions
      global_rank
      regional_rank
      superpowers
      partner_type
      public_sector
      program_status_modified_by
      program_status_modified_date
      psa
      legal_form
      link_to_nda
      previous_usage_code
      previous_usage_code_expiry
      current_usage_code
      current_usage_code_expiry
      is_disabled
      is_admin
      is_reviewer
      admin_region
      notes
      executive_sponsor
      executive_sponsor_email
      retirement_reason
      retirement_date
      suspended_date
      program_level
      auto_approve_contributions
      persona
      createdAt
      updatedAt
    }
  }
`;
export const createAPNContribution = /* GraphQL */ `
  mutation CreateAPNContribution(
    $input: CreateAPNContributionInput!
    $condition: ModelAPNContributionConditionInput
  ) {
    createAPNContribution(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      ace_opportunity_id
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      status_modified_by
      status_modified_date
      updatedAt
    }
  }
`;
export const updateAPNContribution = /* GraphQL */ `
  mutation UpdateAPNContribution(
    $input: UpdateAPNContributionInput!
    $condition: ModelAPNContributionConditionInput
  ) {
    updateAPNContribution(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      ace_opportunity_id
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      status_modified_by
      status_modified_date
      updatedAt
    }
  }
`;
export const deleteAPNContribution = /* GraphQL */ `
  mutation DeleteAPNContribution(
    $input: DeleteAPNContributionInput!
    $condition: ModelAPNContributionConditionInput
  ) {
    deleteAPNContribution(input: $input, condition: $condition) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      ace_opportunity_id
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      status_modified_by
      status_modified_date
      updatedAt
    }
  }
`;
export const createAmbassadorsApplicationEvent = /* GraphQL */ `
  mutation CreateAmbassadorsApplicationEvent(
    $input: CreateAmbassadorsApplicationEventInput!
    $condition: ModelAmbassadorsApplicationEventConditionInput
  ) {
    createAmbassadorsApplicationEvent(input: $input, condition: $condition) {
      id
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateAmbassadorsApplicationEvent = /* GraphQL */ `
  mutation UpdateAmbassadorsApplicationEvent(
    $input: UpdateAmbassadorsApplicationEventInput!
    $condition: ModelAmbassadorsApplicationEventConditionInput
  ) {
    updateAmbassadorsApplicationEvent(input: $input, condition: $condition) {
      id
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteAmbassadorsApplicationEvent = /* GraphQL */ `
  mutation DeleteAmbassadorsApplicationEvent(
    $input: DeleteAmbassadorsApplicationEventInput!
    $condition: ModelAmbassadorsApplicationEventConditionInput
  ) {
    deleteAmbassadorsApplicationEvent(input: $input, condition: $condition) {
      id
      type
      description
      createdAt
      updatedAt
    }
  }
`;
