/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAPNAmbassadorProfile = /* GraphQL */ `
  query GetAPNAmbassadorProfile($cognito_user: String!) {
    getAPNAmbassadorProfile(cognito_user: $cognito_user) {
      cognito_user
      email
      first_name
      last_name
      partner_name
      country
      region
      bio
      twitter
      linkedin
      headshot
      title
      shirt_size
      member_since
      certifications
      program_status
      total_score
      total_approved_contributions
      approved_community_contributions
      approved_business_contributions
      global_rank
      regional_rank
      superpowers
      partner_type
      public_sector
      program_status_modified_by
      program_status_modified_date
      psa
      legal_form
      link_to_nda
      previous_usage_code
      previous_usage_code_expiry
      current_usage_code
      current_usage_code_expiry
      is_disabled
      is_admin
      is_reviewer
      admin_region
      notes
      executive_sponsor
      executive_sponsor_email
      retirement_reason
      retirement_date
      suspended_date
      program_level
      auto_approve_contributions
      persona
      createdAt
      updatedAt
    }
  }
`;
export const listAPNAmbassadorProfiles = /* GraphQL */ `
  query ListAPNAmbassadorProfiles(
    $cognito_user: String
    $filter: ModelAPNAmbassadorProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAPNAmbassadorProfiles(
      cognito_user: $cognito_user
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognito_user
        email
        first_name
        last_name
        partner_name
        country
        region
        bio
        twitter
        linkedin
        headshot
        title
        shirt_size
        member_since
        certifications
        program_status
        total_score
        total_approved_contributions
        approved_community_contributions
        approved_business_contributions
        global_rank
        regional_rank
        superpowers
        partner_type
        public_sector
        program_status_modified_by
        program_status_modified_date
        psa
        legal_form
        link_to_nda
        previous_usage_code
        previous_usage_code_expiry
        current_usage_code
        current_usage_code_expiry
        is_disabled
        is_admin
        is_reviewer
        admin_region
        notes
        executive_sponsor
        executive_sponsor_email
        retirement_reason
        retirement_date
        suspended_date
        program_level
        auto_approve_contributions
        persona
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAPNContribution = /* GraphQL */ `
  query GetAPNContribution($id: ID!) {
    getAPNContribution(id: $id) {
      id
      cognito_user
      region
      email
      output_type
      description
      link
      ace_opportunity_id
      date_completed
      createdAt
      country
      reject_reason
      status
      category
      status_modified_by
      status_modified_date
      updatedAt
    }
  }
`;
export const listAPNContributions = /* GraphQL */ `
  query ListAPNContributions(
    $filter: ModelAPNContributionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAPNContributions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user
        region
        email
        output_type
        description
        link
        ace_opportunity_id
        date_completed
        createdAt
        country
        reject_reason
        status
        category
        status_modified_by
        status_modified_date
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAmbassadorsApplicationEvent = /* GraphQL */ `
  query GetAmbassadorsApplicationEvent($id: ID!) {
    getAmbassadorsApplicationEvent(id: $id) {
      id
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const listAmbassadorsApplicationEvents = /* GraphQL */ `
  query ListAmbassadorsApplicationEvents(
    $filter: ModelAmbassadorsApplicationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmbassadorsApplicationEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
