import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import { Auth } from 'aws-amplify'

import * as JWT from 'jwt-decode';

import { Link } from 'react-router-dom'

import '@amzn/awsui-components-react/index.css';

import {
  AppLayout,
  Button,
  Badge,
  ColumnLayout,
  Icon,
  Spinner,Flash
} from '@amzn/awsui-components-react';
import { appLayoutLabels } from '../common/labels';
import '../styles/form.scss';
import { render } from '@amzn/awsui-components-react/polaris/internal/core/runtime/src/mithril';


class EditContributionForm extends React.Component {
    render(){
        return(<div>
            Form goes here.
        </div>)
    }
}

const AdminEditContribution = () =>{

    return (
        <AppLayout
        contentType='form'
        content={<EditContributionForm/>}
        navigationHide
        toolsHide
        labels={appLayoutLabels}
      /> 
    );
}

export default AdminEditContribution;